























import { defineComponent } from '@nuxtjs/composition-api';
import { useFeaturedBrands } from '~/features/brands';

export default defineComponent({
  setup() {
    const { brands } = useFeaturedBrands({
      filter: { is_in_slider: true },
      pageSize: 20,
    });
    return {
      brands,
    };
  },
});
