import { render, staticRenderFns } from "./FeaturedBrandsFragment.vue?vue&type=template&id=248084df&scoped=true&"
import script from "./FeaturedBrandsFragment.vue?vue&type=script&lang=ts&"
export * from "./FeaturedBrandsFragment.vue?vue&type=script&lang=ts&"
import style0 from "./FeaturedBrandsFragment.vue?vue&type=style&index=0&id=248084df&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248084df",
  null
  
)

/* custom blocks */
import block0 from "./FeaturedBrandsFragment.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppLink.js').default,BrandSlider: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/BrandSlider.vue').default})
