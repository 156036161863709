









































import { defineComponent, nextTick, onMounted, ref, toRef, watch } from '@nuxtjs/composition-api';

// eslint-disable-next-line import/no-named-as-default
import Swiper from 'swiper';

export default defineComponent({
  name: 'BrandSlider',
  props: {
    items: {
      type: Array,
      required: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const swiperRef = ref(null);
    const isSliderLoaded = ref(false);
    const items = toRef(props, 'items');
    const swiperInstance = ref<Swiper>();

    onMounted(async () => {
      const { default: Swiper, Navigation, Autoplay } = await import('swiper');
      Swiper.use([Navigation]);
      Swiper.use([Autoplay]);

      // @ts-ignore
      await import('swiper/swiper-bundle.css');

      isSliderLoaded.value = true;
      await nextTick();

      swiperInstance.value = new Swiper(swiperRef.value || '', {
        slidesPerView: 3,
        spaceBetween: 15,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        },
        autoplay: props.autoPlay
          ? {
              delay: props.autoPlay,
            }
          : false,
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      watch(items, () => {
        swiperInstance?.value?.updateSlides();
      });
    });

    function nextSlider() {
      swiperInstance?.value?.slideNext();
    }

    function prevSlider() {
      swiperInstance?.value?.slidePrev();
    }

    return {
      swiperRef,
      isSliderLoaded,
      swiperInstance,
      prevSlider,
      nextSlider,
    };
  },
});
